import { Component, Vue } from "vue-property-decorator";

import { STHeader,STFooter } from "@/layout";
import { HomeworkModel } from "@/models";
import { StCommon } from "../Common";

export class QuizForm {
    shid = '';
    name = '';
    student = '';
    city = '';
    school = '';
    class = '';
    unit = '';
    level = '';
    grade = '';
    question = [];
    currentQuestion = 0;
    score = 0;
    date = '';
    completeDate = '';
}

type response = { [key: string]: unknown };

@Component<ApStudentHomeworkVerifyInfoController>({
    components: {
        STHeader,STFooter
    }
})

export default class ApStudentHomeworkVerifyInfoController extends Vue {
    private quizForm = new QuizForm();
    private token = window.localStorage.getItem('studentToken') as string;
    private stid = window.localStorage.getItem('student_stid') as string;

    public async created() {
        const item = {
            shid: this.$route.query.shid,
            stid: this.stid,
            token: this.token,
        }
        const editData = await HomeworkModel.stGetResultMem(item) as response;
        this.assignDataToForm(editData);
    }

    private assignDataToForm(data: response) {
        StCommon.checkToken(data.ERR_CODE as number);

        if (Object.keys(data.info as object).length == 0) {
            this.$router.push('/student/homework/verify');
            return;
        }
        const item = data.info as {
            shid: string;
            name: string;
            student: string;
            city: string;
            school: string;
            class: string;
            unit: string;
            level: string;
            grade: string;
            date: string;
            completeDate: string;
            question: [];
            currentQuestion: number;
            score: number;
        };
        this.quizForm.shid = item.shid;
        this.quizForm.name = item.name;
        this.quizForm.student = item.student;
        this.quizForm.city = item.city;
        this.quizForm.school = item.school;
        this.quizForm.class = item.class;
        this.quizForm.unit = item.unit;
        this.quizForm.level = item.level;
        this.quizForm.grade = item.grade;
        this.quizForm.date = item.date;
        this.quizForm.completeDate = item.completeDate;
        this.quizForm.question = item.question;
        this.quizForm.currentQuestion = item.currentQuestion;
        this.quizForm.score = item.score;
    }
}